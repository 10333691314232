<!--
 * @Author: zhangjingqing
 * @Date: 2022-05-03 13:09:02
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-22 14:59:39
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\combRiskIndex\Index.vue
-->
<template>
  <div class="container">
    <div class="options">
      <a-form layout="inline">
        <a-form-item label="时间" style="margin-left: 20px">
          <a-select
            placeholder="请选择时间"
            style="width: 120px; margin-left: 20px"
            v-model="year"
            @change="getRiskComb"
          >
            <a-select-option v-for="item in yearList" :key="item" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="纳税人性质" style="margin-left: 20px">
          <a-select
            placeholder="请选择纳税人性质"
            style="width: 150px; margin-left: 20px"
            v-model="taxpayerNature"
            @change="getRiskComb"
          >
            <a-select-option :value="0"> 一般纳税人 </a-select-option>
            <a-select-option :value="1"> 小规模纳税人 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="指标准则类型" style="margin-left: 20px">
          <a-select
            placeholder="请选择指标准则类型"
            style="width: 200px; margin-left: 20px"
            v-model="indexStandardType"
            @change="getRiskComb"
          >
            <a-select-option :value="0"> 企业会计准则（2007） </a-select-option>
            <a-select-option :value="1"> 企业会计准则（2019） </a-select-option>
            <a-select-option :value="2">
              小企业会计准则（2013）
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="时间类型">
          <a-button-group style="margin-left: 20px">
            <a-button
              :type="dataType == 0 ? 'primary' : ''"
              @click="dataType = 0"
              >年度</a-button
            >
            <a-button
              :type="dataType == 1 ? 'primary' : ''"
              @click="dataType = 1"
              >季度</a-button
            >
            <a-button
              :type="dataType == 2 ? 'primary' : ''"
              @click="dataType = 2"
              >月度</a-button
            >
          </a-button-group>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" icon="plus" @click="handleAdd"
            >新增组合</a-button
          >
        </a-form-item>
      </a-form>
    </div>
    <a-table :columns="columns" :data-source="data" :loading="loading">
      <span slot="calRuleMainVoList" slot-scope="text, record">
        {{ getIndexs(record.calRuleMainVoList) }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)">编辑</a>
        <a-divider type="vertical" />
        <a @click="handleDelete(record.id)">删除</a>
      </span>
    </a-table>
    <comb-risk-modal
      ref="combRiskModal"
      @reload="getRiskComb"
    ></comb-risk-modal>
  </div>
</template>
<script>
import { getYear } from "@/api/tax";
import { getRiskComb, deleteRiskComb } from "@/api/comb";
import CombRiskModal from "./CombRiskModal.vue";
const columns = [
  {
    title: "组合名称",
    dataIndex: "comName",
    key: "comName",
  },
  {
    title: "组合明细",
    dataIndex: "calRuleMainVoList",
    key: "calRuleMainVoList",
    scopedSlots: { customRender: "calRuleMainVoList" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "",
  components: { CombRiskModal },
  data() {
    return {
      loading: false,
      dataType: 0,
      year: 0,
      yearList: [],
      taxpayerNature: 0,
      indexStandardType: 0,
      data: [],
      columns,
    };
  },
  async created() {
    await this.getYear();
    await this.getRiskComb();
  },
  methods: {
    async getYear() {
      let params = {
        type: 2,
      };
      let res = await getYear(params);
      if (res.status == 200) {
        let years = res.data;
        this.yearList = years;
        this.year = years[0];
      }
    },
    getIndexs(list) {
      let arr = [];
      list.forEach((item) => {
        arr.push(item.indexName);
      });
      return arr.join("，");
    },
    async getRiskComb() {
      this.loading = true;
      let params = {
        dateType: this.dataType,
        year: this.year,
        taxpayerNature: this.taxpayerNature,
        indexStandardType: this.indexStandardType,
      };
      let res = await getRiskComb(params);
      if (res.status == 200) {
        this.data = res.data;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
      this.loading = false;
    },
    handleAdd() {
      this.$refs.combRiskModal.show(
        this.dataType,
        this.year,
        this.taxpayerNature,
        this.indexStandardType,
        2
      );
    },
    handleEdit(record) {
      this.$refs.combRiskModal.show(
        this.dataType,
        this.year,
        this.taxpayerNature,
        this.indexStandardType,
        3,
        record
      );
    },
    handleDelete(id) {
      const that = this;
      this.$confirm({
        title: "确认删除",
        content: "是否删除当前数据?",
        onOk: async function () {
          let res = await deleteRiskComb({ id: id });
          if (res.status == 200) {
            that.$message.success("删除成功！");
            that.getRiskComb();
          } else {
            that.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        },
      });
    },
  },
  watch: {
    dataType() {
      this.getRiskComb();
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  padding: 20px;
  background: #fff;
  min-height: 280px;
}
.options {
  padding-bottom: 16px;
}
</style>