<!--
 * @Author: zhangjingqing
 * @Date: 2022-05-03 21:06:53
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-04 17:56:47
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\combRiskIndex\CombRiskModal.vue
-->
<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleSave"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="组合风险指标名称">
        <a-input
          v-decorator="[
            'comName',
            {
              rules: [{ required: true, message: '组合风险指标名称不能为空' }],
            },
          ]"
          placeholder="请输入组合风险指标名称"
        />
      </a-form-item>
    </a-form>
    <div class="cover">
      <a-transfer
        :data-source="originData"
        :titles="['风险指标', '组合风险指标']"
        :target-keys="targetKeys"
        :selected-keys="selectedKeys"
        :render="(item) => item.title"
        @change="handleChange"
        @selectChange="handleSelectChange"
      />
    </div>
  </a-modal>
</template>
<script>
import { getRisk, createRiskComb, updateRiskComb } from "@/api/comb";
export default {
  name: "",
  data() {
    return {
      visible: false,
      confirmLoading: false,
      status: 2,
      dateType: "",
      year: "",
      taxpayerNature: "",
      indexStandardType: "",
      form: this.$form.createForm(this, { name: "form" }),
      record: {},
      originData: [],
      targetKeys: [],
      selectedKeys: [],
    };
  },
  methods: {
    async show(
      dateType,
      year,
      taxpayerNature,
      indexStandardType,
      status,
      record
    ) {
      this.dateType = dateType;
      this.year = year;
      this.taxpayerNature = taxpayerNature;
      this.indexStandardType = indexStandardType;
      this.status = status;
      this.visible = true;
      await this.getRisk(dateType, year);
      if (record.id) {
        this.record = record;
        this.$nextTick(() => {
          this.form.setFieldsValue({ comName: record.comName });
        });
        let ids = [];
        record.calRuleMainVoList.forEach((item) => {
          ids.push(item.id.toString());
        });
        this.targetKeys = ids;
      }
    },
    async getRisk(dateType, year) {
      let params = {
        dateType: dateType,
        indexStandardType: this.indexStandardType,
        taxpayerNature: this.taxpayerNature,
        year: year,
      };
      let res = await getRisk(params);
      if (res.status == 200) {
        let data = res.data;
        this.originData = [];
        data.forEach((item) => {
          let node = {
            key: item.id.toString(),
            title: item.indexName,
          };
          this.originData.push(node);
        });
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    async handleSave() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (this.targetKeys.length != 0) {
            let params = {
              comName: values.comName,
              dateType: this.dateType,
              indexId: this.targetKeys.join(","),
              indexStandardType: this.indexStandardType,
              taxpayerNature: this.taxpayerNature,
              year: this.year,
            };
            let res;
            if (this.status == 2) {
              res = await createRiskComb(params);
            } else {
              res = await updateRiskComb(
                Object.assign({}, this.record, params)
              );
            }
            if (res.status == 200) {
              this.handleCancel();
              this.visible = false;
              this.$emit("reload");
            } else {
              this.$notification.error({
                message: "系统提示",
                description: res.msg || res.message,
                duration: 4,
              });
            }
          } else {
            this.$message.warning("组合指标不能为空！");
          }
        }
      });
    },
    handleCancel() {
      this.form.resetFields();
      this.originData = [];
      this.targetKeys = [];
      this.visible = false;
    },
    handleChange(nextTargetKeys) {
      this.targetKeys = nextTargetKeys;
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
  },
  computed: {
    title() {
      let title;
      switch (this.status) {
        case 2:
          title = "新增组合风险指标";
          break;
        case 3:
          title = "编辑";
          break;
      }
      return title;
    },
  },
};
</script>
<style lang="less" scoped>
.cover {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>